// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const CandidateClarif = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "Çalışan Adayı Aydınlatma Beyanı",
            description: `
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“ÜNLÜ TEKSTİL ya da Şirket”) olarak veri sorumlusu sıfatımızın bilinciyle kişisel verilerinizin korunmasına azami ölçüde önem veriyoruz.</p>
                <h2 style="font-size:20px">1. KİŞİSEL VERİLERİNİZ VE İŞLEME AMAÇLARIMIZ</h4>
                <p>Şirketimiz tarafından işlenecek kişisel verileriniz ve bunların işlenme amaçları aşağıda belirtilmiştir.</p>
                <table>
                    <thead>
                        <tr>
                            <th>Kişisel Veriler</th>
                            <th>İşleme Amacı</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Kimlik ve iletişim bilgileriniz, eğitim bilgileriniz, mesleki bilgileriniz, son çalıştığınız işyeri ve pozisyona ilişkin bilgileriniz, referans bilgileriniz</td>
                            <td>İş başvurunuzun değerlendirilebilmesi ve bu kapsamdaki insan kaynakları süreçlerinin yürütülebilmesi</td>
                        </tr>
                        <tr>
                            <td>Özgeçmiş (CV) ve benzeri belgelerde yer alan bilgileriniz</td>
                            <td>Rıza göstermeniz halinde başvurunuzun gelecek pozisyonlarda değerlendirilmek üzere başvuru havuzumuzda saklanabilmesi</td>
                        </tr>
                    </tbody>
                </table>
                <h2 style="font-size:20px">2. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</h4>
                <p>Kişisel verileriniz, iş başvuru formu ve özgeçmişiniz, yüz yüze mülakatlar, e-posta ve telefon görüşmeleri ya da elektronik sistemlerin kullanımı gibi araçlarla doğrudan sizden veya üçüncü kişi istihdam platformlarından fiziki ve elektronik ortamda, otomatik olan ve otomatik olmayan yollarla elde edilmekte ve işlenmektedir.</p>
                <p>Kişisel verilerinizin toplanmasının hukuki dayanakları, bir sözleşmenin kurulabilmesi için gereklilik, Şirketimizin meşru menfaatleri ve bazı durumlarda açık rızanızdır.</p>
                <h2 style="font-size:20px">3. KİŞİSEL VERİLERİNİZİN KİMLERE VE HANGİ AMAÇLARLA AKTARILABİLECEĞİ</h4>
                <p>Kişisel verileriniz prensip olarak herhangi bir üçüncü kişiye aktarılmayacaktır. Ancak Şirketimizin hukuki yükümlülükleri gereği kişisel verileriniz, kanunen yetkili kamu kurum ve kuruluşları ile paylaşılabilecektir.</p>
                <h2 style="font-size:20px">4. KİŞİSEL VERİLERİNİZ İLE İLGİLİ HAKLARINIZ</h4>
                <p>ÇALIŞAN ADAYLARIMIZ;</p>
                <ul>
                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                    <li>(d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
                </ul>
                <p>haklarına sahiptir.</p>
                <p>Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde sayılan yukarıdaki haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e uygun olarak Şirketimize iletebilirsiniz. En hızlı ve etkili biçimde sonuçlandırılabilmesini teminen taleplerinizi, Kişisel Verilerin Korunması Kanunu <a href="/tr/sub8">Veri Sorumlusuna Başvuru Formu</a>’nu doldurarak yapmanızı rica ederiz.</p>
                <p>Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere Kişisel Verilerinin Korunmasına İlişkin Aydınlatma Metnimizden ulaşabilirsiniz.</p>
                <p>Siz değerli çalışan adaylarımızın bilgilerine sunarız.</p>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</p>
            `
        },
        en: {
            title: "Candidate Clarification Statement",
            description: `
                <p>As ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“ÜNLÜ TEKSTİL or the Company”), we take the utmost care to protect your personal data as the data controller.</p>
                <h2 style="font-size:20px">1. YOUR PERSONAL DATA AND OUR PROCESSING PURPOSES</h4>
                <p>The personal data that will be processed by our Company and their processing purposes are specified below.</p>
                <table>
                    <thead>
                        <tr>
                            <th>Personal Data</th>
                            <th>Processing Purpose</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Your identity and contact information, education information, professional information, information about your last employer and position, reference information</td>
                            <td>To evaluate your job application and carry out the related human resources processes</td>
                        </tr>
                        <tr>
                            <td>Your information contained in your CV and similar documents</td>
                            <td>If you consent, to keep your application in our database for future positions</td>
                        </tr>
                    </tbody>
                </table>
                <h2 style="font-size:20px">2. METHOD OF COLLECTION AND LEGAL BASIS OF YOUR PERSONAL DATA</h4>
                <p>Your personal data is obtained and processed through tools such as the job application form and your CV, face-to-face interviews, emails, phone calls, or the use of electronic systems, directly from you or from third-party employment platforms, through physical and electronic means, by both automatic and non-automatic methods.</p>
                <p>The legal grounds for the collection of your personal data are the necessity for the establishment of a contract, our legitimate interests, and in some cases your explicit consent.</p>
                <h2 style="font-size:20px">3. TO WHOM AND FOR WHAT PURPOSES YOUR PERSONAL DATA MAY BE TRANSFERRED</h4>
                <p>Your personal data will not be shared with any third parties in principle. However, due to our legal obligations, your personal data may be shared with legally authorized public institutions and organizations.</p>
                <h2 style="font-size:20px">4. YOUR RIGHTS REGARDING YOUR PERSONAL DATA</h4>
                <p>OUR CANDIDATES HAVE THE RIGHTS TO;</p>
                <ul>
                    <li>Learn whether personal data is processed,</li>
                    <li>Request information regarding processed personal data,</li>
                    <li>Learn the purpose of processing personal data and whether they are used in accordance with their purpose,</li>
                    <li>Know the third parties to whom personal data is transferred, domestically or abroad,</li>
                    <li>Request the correction of personal data if they are incomplete or incorrectly processed,</li>
                    <li>Request the deletion or destruction of personal data under the conditions stipulated in Article 7 of the Law,</li>
                    <li>Request that the operations carried out under (d) and (e) be notified to third parties to whom personal data is transferred,</li>
                    <li>Object to the emergence of a result against the person by analyzing the processed data exclusively through automated systems,</li>
                    <li>Request compensation for damages in case of damage due to unlawful processing of personal data,</li>
                </ul>
                <p>are entitled to these rights.</p>
                <p>You can submit your requests regarding your rights listed above in Article 11 of the Personal Data Protection Law to our Company in accordance with the Communiqué on the Procedures and Principles of Application to the Data Controller. To ensure the fastest and most efficient conclusion of your requests, we kindly ask you to fill out the Application Form for the Data Controller under the Personal Data Protection Law.</p>
                <p>You can access detailed information about the processing of your personal data in our Clarification Text Regarding the Protection of Personal Data.</p>
                <p>We present this information to our valued candidates.</p>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</p>
            `
        }
    };
    
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(CandidateClarif);