import React, {Component} from 'react';
import {connect} from "react-redux";
import { NavLink } from "react-router-dom";
import {
  SLASH_ROUTE,
} from "../../constants/routePaths";

class ConversationComponent extends Component {

    getSubMenuItems = () => {
        const { activeLang } = this.props;
    
        const subMenuItems = {
          tr: [
            { name: "Öneri ve Şikayet Aydınlatma Metni", link: "sub3" },
            { name: "İş ilanı - İş Başvuru Sorumsuzluk Kaydı", link: "sub9" },
          ],
          en: [
            { name: "Suggestion and Complaint Clarification Text", link: "sub3" },
            { name: "Job Posting - Job Application Liability Record", link: "sub9" },
          ],
        };
    
        return subMenuItems[activeLang] || subMenuItems['tr'];
      };
    
    render() {
        let {contactData,activeLang} = this.props;

    const subMenuItems = this.getSubMenuItems();

        return (
           <div className="conversation-text-block">
            <ul>
               {subMenuItems.map((subItem, subIndex) => (
                        <li key={subIndex} style={{ padding: "3px 0", color: "black", cursor: "pointer", textAlign: "left" }}>
                          <NavLink
                            to={`${SLASH_ROUTE}${activeLang}/${subItem.link}`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                              fontSize: "20px", // Yazı boyutunu düşür
                              fontFamily:"Montserrat-SemiBold",
                            }}
                            activeStyle={{
                              color: "orange", // Aktif bağlantı rengi
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.color = "orange"}
                            onMouseLeave={(e) => e.currentTarget.style.color = "black"}
                          >
                            {subItem.name}
                          </NavLink>
                        </li>
                      ))}
                      </ul>

               {
                   contactData &&
                   <div className="conversation-item">
                       <h3>{contactData.conversation.title}</h3>
                       {
                           contactData.conversation.descs.map((descItem,key)=>
                               <p key={key}>{descItem}</p>
                           )
                       }
                   </div>
               }
           </div>
        );
    }
}
const mapStateToProps = (state) => ({
    contactData: state.contactReducer[state.langReducer.activeLang] ? state.contactReducer[state.langReducer.activeLang].contactData : null,
  activeLang: state.langReducer.activeLang,

});


export default connect (mapStateToProps)(ConversationComponent);