// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const Intorn = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "ÜNLÜ TEKSTİL Stajyer Aydınlatma Beyanı",
            description: `
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“Ünlü Tekstil” veya “Şirket”) olarak veri sorumlusu sıfatımızın bilinciyle kişisel verilerinizin korunmasına azami ölçüde önem veriyoruz.
                
                <h2 style="font-size:20px">1. KİŞİSEL VERİLERİNİZ VE İŞLEME AMAÇLARIMIZ</h2>
                Şirketimiz tarafından işlenecek kişisel verileriniz ve bunların işlenme amaçları aşağıda belirtilmiştir.
                
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>Kişisel Veriler</th>
                        <th>İşleme Amacı</th>
                    </tr>
                    <tr>
                        <td>Kimlik, iletişim, özlük, hukuki işlem, mesleki deneyim, finans bilgileri</td>
                        <td>İşe giriş ve işten çıkış dâhil insan kaynakları ve özlük süreçlerinin yürütülmesi, özlük dosyasının oluşturulması, hak ve menfaatlerin ödenmesi, masraf ödeme işlemlerinin gerçekleştirilmesi, performans yönetimi, takibi ve değerlendirmesinin yapılması, resmi kayıt ve bildirim işlemlerinin gerçekleştirilmesi, hukuki yükümlülüklerin yerine getirilmesi.</td>
                    </tr>
                    <tr>
                        <td>Kimlik, iletişim, özlük ve sağlık bilgileri, Kangru bilgisi, istirahat raporu, iş kazası sonrası sağlık bilgileri</td>
                        <td>Sağlık durumu takibinin yapılması, iş sağlığının temini, hukuki yükümlülüklerin yerine getirilmesi, iş sağlığı ve güvenliği çalışmalarının kayıt altına alınması, iş kazalarının araştırılması ve takibi, iş sağlığı ve güvenliğinin temini, hukuki yükümlülüklerin yerine getirilmesi, hastalık, iş kazası gibi iş göremezlik hallerinde sağlık durumunu gösterir kayıtların işlenmesi.</td>
                    </tr>
                    <tr>
                        <td>Kimlik, iletişim, çalışan yakını acil iletişim kişisine ait kimlik ve iletişim bilgileri</td>
                        <td>Herhangi bir acil durumda çalışan yakınlarıyla iletişim kurulması.</td>
                    </tr>
                    <tr>
                        <td>Güvenlik kamerası kayıtları kapsamında görsel veriler</td>
                        <td>Bina ve çalışan güvenliğinin sağlanabilmesi.</td>
                    </tr>
                    <tr>
                        <td>Bilgisayar ve mobil cihazların Şirket ağından internete erişimine dair kayıtlar</td>
                        <td>5651 sayılı kanun uyarınca hukuki yükümlülüklerin yerine getirilmesi, iş ve süreç verimliliğinin takibi ve iyileştirilmesi.</td>
                    </tr>
                    <tr>
                        <td>Özel Nitelikli Kişisel Veriler</td>
                        <td>Açık rızanın alınması.</td>
                    </tr>
                    <tr>
                        <td>Görsel ve işitsel kayıtlar kategorisindeki uzaktan eğitim video kayıtları</td>
                        <td>Eğitim faaliyetlerinin mevzuata uygun yürütülmesi ve video eğitim faaliyetlerinin gerçekleştirilmesi amaçlarıyla işlenmektedir.</td>
                    </tr>
                </table>
                
                <h2 style="font-size:20px">2. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</h2>
                Kişisel verileriniz, fiziki ve elektronik ortamda, otomatik ve otomatik olmayan yollarla tarafınızdan veya özlük dosyanızdan elde edilmekte ve işlenmektedir. Kişisel verileriniz, örneğin bizzat tarafınızca doldurulan çeşitli formlar veya ibraz ettiğiniz belgeler aracılığıyla otomatik olmayan yollarla veya e-posta, şirket cihazları ve diğer kaynaklarının kullanımı ya da kamera gibi araçlarla otomatik yollarla toplanabilmektedir. Kişisel verilerinizin toplanmasının hukuki dayanakları iş sözleşmeniz, kanunlarda öngörülme, Şirketimizin hukuki yükümlülükleri ve meşru menfaatleridir.
                
                <h2 style="font-size:20px">3. KİŞİSEL VERİLERİNİZİN KİMLERE VE HANGİ AMAÇLARLA AKTARILABİLECEĞİ</h2>
                Kişisel verilerinizin aktarılabileceği üçüncü kişiler ve bu üçüncü kişilere aktarımın amaçları aşağıda belirtilmiştir. 
                
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>Alıcı Grubu</th>
                        <th>Aktarım Amacı</th>
                    </tr>
                    <tr>
                        <td>Gerçek kişiler veya özel hukuk tüzel kişileri (danışmanlar, tedarikçiler, bankalar gibi)</td>
                        <td>İşe giriş ve işten çıkış dâhil insan kaynakları ve özlük süreçlerinin yürütülmesi, özlük dosyasının oluşturulması, ücret ve masraf ödeme işlemlerinin gerçekleştirilmesi, Şirketimizin yasal haklarının takibi ve kullanımı.</td>
                    </tr>
                    <tr>
                        <td>Kanunen yetkili kamu kurum ve kuruluşları (Sosyal Güvenlik Kurumu, kolluk kuvvetleri, mahkemeler gibi)</td>
                        <td>Hukuki yükümlülüklere uyum.</td>
                    </tr>
                    <tr>
                        <td>Mesleki Deneyim</td>
                        <td>Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, Eğitim ve mesleki konularında İşkur, Milli Eğitim Bakanlığı ve benzeri kurumlara kişisel verilerin işlenmesinin gerekli olması.</td>
                    </tr>
                </table>
                
                <h2 style="font-size:20px">4. KİŞİSEL VERİLERİNİZ İLE İLGİLİ HAKLARINIZ</h2>
                Personellerimiz;
                <ul>
                    <li>a) Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>e) Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                    <li>f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
                </ul>
                haklarına sahiptir. Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde sayılan yukarıdaki haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e uygun olarak Şirketimize iletebilirsiniz. En hızlı ve etkili biçimde sonuçlandırılabilmesini teminen taleplerinizi, Kişisel Verilerin Korunması Kanunu Başvuru Formu’nu doldurarak yapmanızı rica ederiz.
                
                Siz değerli personellerimizin bilgilerine sunarız.
                <br /><br />
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş
            `,
        },
        en: {
            title: "ÜNLÜ TEKSTİL Intern Trainee Disclosure Statement",
            description: `
                As ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“Ünlü Tekstil” or “Company”), we attach utmost importance to the protection of your personal data, being aware of our responsibilities as data controllers.
                
                <h2 style="font-size:20px">1. YOUR PERSONAL DATA AND OUR PROCESSING PURPOSES</h2>
                The personal data that will be processed by our Company and their processing purposes are specified below.
                
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>Personal Data</th>
                        <th>Processing Purpose</th>
                    </tr>
                    <tr>
                        <td>Identity, contact, personnel, legal transaction, professional experience, financial information</td>
                        <td>Conducting human resources and personnel processes including recruitment and termination, creating personnel files, payment of rights and benefits, expense payment transactions, performance management, tracking and evaluation, conducting official record and notification processes, and fulfilling legal obligations.</td>
                    </tr>
                    <tr>
                        <td>Identity, contact, personnel and health information, Kangru information, sick leave reports, post-accident health information</td>
                        <td>Monitoring health status, ensuring occupational health, fulfilling legal obligations, recording health and safety studies, investigating and tracking workplace accidents, ensuring occupational health and safety, processing records that demonstrate your health status in cases of inability to work due to illness or accidents.</td>
                    </tr>
                    <tr>
                        <td>Identity, contact, emergency contact person’s identity and contact information</td>
                        <td>Contacting employee relatives in the event of an emergency.</td>
                    </tr>
                    <tr>
                        <td>Visual data within the scope of security camera recordings</td>
                        <td>Ensuring building and employee security.</td>
                    </tr>
                    <tr>
                        <td>Records of access to the internet from company computers and mobile devices</td>
                        <td>Fulfilling legal obligations under Law No. 5651, tracking and improving business and process efficiency.</td>
                    </tr>
                    <tr>
                        <td>Special Categories of Personal Data</td>
                        <td>Obtaining explicit consent.</td>
                    </tr>
                    <tr>
                        <td>Visual and auditory records in the category of remote training video recordings</td>
                        <td>Processed for the purpose of conducting training activities in accordance with the regulations and conducting video training activities.</td>
                    </tr>
                </table>
                
                <h2 style="font-size:20px">2. METHOD OF COLLECTION AND LEGAL BASIS OF YOUR PERSONAL DATA</h2>
                Your personal data is obtained and processed in physical and electronic environments, by automatic and non-automatic means, from you or your personnel file. Your personal data may be collected automatically through tools such as cameras or by non-automatic means through various forms filled out by you or documents you present, or via email, company devices, and other sources. The legal bases for the collection of your personal data are your employment contract, legal provisions, our Company’s legal obligations, the exercise of rights, and legitimate interests.
                
                <h2 style="font-size:20px">3. TO WHOM AND FOR WHAT PURPOSES YOUR PERSONAL DATA MAY BE TRANSFERRED</h2>
                The third parties to whom your personal data may be transferred and the purposes of such transfers are specified below.
                
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>Recipient Group</th>
                        <th>Transfer Purpose</th>
                    </tr>
                    <tr>
                        <td>Real persons or private legal entities (such as consultants, suppliers, banks)</td>
                        <td>Conducting human resources and personnel processes including recruitment and termination, creating personnel files, executing salary and expense payment transactions, tracking and utilizing the Company’s legal rights.</td>
                    </tr>
                    <tr>
                        <td>Legally authorized public institutions and organizations (such as the Social Security Institution, law enforcement agencies, courts)</td>
                        <td>Compliance with legal obligations.</td>
                    </tr>
                    <tr>
                        <td>Professional Experience</td>
                        <td>As long as it is directly related to the establishment or performance of the contract, it is necessary to process personal data of the parties in matters of training and employment from institutions such as İşkur, Ministry of National Education, and similar organizations.</td>
                    </tr>
                </table>
                
                <h2 style="font-size:20px">4. YOUR RIGHTS REGARDING YOUR PERSONAL DATA</h2>
                Our personnel have the following rights:
                <ul>
                    <li>a) To learn whether personal data is being processed,</li>
                    <li>b) To request information regarding personal data if it has been processed,</li>
                    <li>c) To learn the purpose of processing personal data and whether it is used appropriately for that purpose,</li>
                    <li>ç) To know third parties to whom personal data are transferred domestically or abroad,</li>
                    <li>d) To request correction of personal data if it is incomplete or inaccurately processed,</li>
                    <li>e) To request deletion or destruction of personal data under the conditions specified in Article 7 of the Law,</li>
                    <li>f) To request that the operations conducted under (d) and (e) be notified to third parties to whom personal data are transferred,</li>
                    <li>g) To object to the emergence of a result against the person by means of exclusively automated systems analyzing the processed data,</li>
                    <li>ğ) To request compensation for damages in case of damage due to unlawful processing of personal data,</li>
                </ul>
                You may submit your requests regarding the above-mentioned rights listed in Article 11 of the Personal Data Protection Law to our Company in accordance with the Regulation on the Procedure and Principles of Application to the Data Controller. We kindly ask you to complete the Personal Data Protection Law Application Form to ensure the fastest and most effective resolution of your requests.
                
                We present this information to our valued personnel.
                <br /><br />
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş
            `,
        },
    };
    
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(Intorn);