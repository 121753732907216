// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const SecurityCamera = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "Güvenlik Kameralarına İlişkin Aydınlatma Beyanı",
            description: `
                <h2 style="font-size:20px">Güvenlik Kameralarına İlişkin Aydınlatma Beyanı</h1>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş. olarak veri sorumlusu sıfatımızın bilinciyle kişisel verilerinizin korunmasına azami ölçüde önem veriyoruz.</p>
                <p>Bina ve ofis güvenliğinin sağlanması amacıyla Şirketimizde 7 gün 24 saat kamera kaydı yapılmaktadır. Bu kapsamdaki kişisel verileriniz, kameralar aracılığıyla otomatik yollarla elde edilmekte ve işlenmektedir. Kişisel verilerinizin toplanmasının hukuki dayanağı, Şirketimizin meşru menfaatleridir. Yetkilendirilmiş Yükümlülük Sistemi için ilgili Gümrük mevzuatı hukuki esasları geçerlidir.</p>
                <p>Kamera kayıtlarındaki kişisel verileriniz kural olarak üçüncü kişilere aktarılmamaktadır. Ancak hukuki yükümlülük gereği veya kanuni haklarımızın korunmasını teminen yetkili kamu kurum ve kuruluşlarıyla paylaşım yapılabilecektir. Kayıtların saklama süreleri için Saklama ve İmha Politikası ve KVKK Kurum sitesi VERBİS Envanterine bakılabilir.</p>
                <p>Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde sayılan haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e uygun olarak hazırlanan KVKK <a href="/tr/sub8">Veri Sorumlusuna Başvuru Formu</a> ile Şirketimize iletebilirsiniz.</p>
            `
        },
        en: {
            title: "Clarification Statement Regarding Security Cameras",
            description: `
                <h2 style="font-size:20px">Clarification Statement Regarding Security Cameras</h1>
                <p>As ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş., we prioritize the protection of your personal data with the awareness of our role as the data controller.</p>
                <p>For the purpose of ensuring the security of our buildings and offices, our Company records video 24/7. Your personal data in this context is obtained and processed automatically through cameras. The legal basis for collecting your personal data is our Company's legitimate interests. The legal basis for the Authorized Liability System is governed by relevant customs legislation.</p>
                <p>As a rule, your personal data in the camera recordings is not shared with third parties. However, it may be shared with authorized public institutions and organizations as required by legal obligations or to protect our legal rights. For the retention periods of the recordings, please refer to the Retention and Destruction Policy and the KVKK Institution's VERBİS Inventory.</p>
                <p>You can submit your requests regarding your rights listed in Article 11 of the Personal Data Protection Law using the KVKK Data Controller Application Form prepared in accordance with the Regulation on the Principles and Procedures for Applications to the Data Controller.</p>
            `
        }
    };
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(SecurityCamera);