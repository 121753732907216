// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const JobPosting = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "İş İlanı/İş Başvuru Formu Sorumsuzluk Kaydı",
            description: `
                6698 Sayılı Kişisel Verilerin Korunması Kanunu ve ikincil mevzuatında düzenlenen veri minimizasyonu ilkesi gereğince aday değerlendirme süreçlerimiz iş ilanında/iş başvuru formunda talep edilen bilgi ve belgeler üzerinden ilerleyecektir. Talep edilen bilgi ve belgelerin sürecin eksiksiz yürütülmesi için gerekli ve yeterli olduğunu hatırlatarak, ek bilgi ve belgelerin teslimi durumunda bunların saklanması ve korunmasından Şirketimizin sorumlu olmadığını bildiririz.
                <br> </br>Adayın, yakınlarına> ve referanslarına ait kişisel verileri Şirketimize iletmesi durumunda, kişisel verisi işlenen kişilerin bu konuda aday tarafından aydınlatıldığı ve gerektiği hallerde rızasının alındığı kabul edilir. Aksi durumda Şirketimiz sorumlu tutulamaz.
                <br/><br/>
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş
            `,
        },
        en: {
            title: "Job Posting/Job Application Form Disclaimers",
            description: `
                According to the data minimization principle regulated by the Law on the Protection of Personal Data No. 6698 and its secondary legislation, our candidate evaluation processes will proceed based on the information and documents requested in the job posting/job application form. We remind you that the requested information and documents are necessary and sufficient for the flawless execution of the process, and we inform you that our Company will not be responsible for the storage and protection of any additional information and documents submitted.
                If the candidate provides personal data of their relatives and references to our Company, it is assumed that the persons whose personal data are processed have been informed by the candidate and that their consent has been obtained where necessary. Otherwise, our Company cannot be held responsible.
                <br/><br/>
                ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş
            `,
        },
    };
    
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];
    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };
    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(JobPosting);