import React, {Component,} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import HomePage from '../pages/homePage';
import ArticleDetail from "../pages/article/articleDetail";
import ArticleList from "../pages/article/articleList";
import Corporate from "../pages/corporate/corporate";
import {
    ARTICLE_DETAIL,
    HOME,
    ARTICLE_LIST,
    CORPORATE,
    CONTACT,
    PRIVACY,
    COOKIE,
    SUSTAINBILITY,
    LANG_EN, SLASH_ROUTE, LANG_TR, CORPORATE_WITHOUT_LINK, CONTACT_WITHOUT_LINK, PRODUCTIVITY, KVKK
} from "../constants/routePaths";
import Contact from "../pages/contact/contact";
import HeaderComponent from "./layout/headerComponent";
import PrivacyPolicy from "../pages/policy/privacyPolicy";
import CookiePolicyPage from "../pages/policy/cookiePolicyPage";
import FooterComponent from "./layout/footer/footerComponent";
import {connect} from "react-redux";
import {changeLangAction} from "../actions/langAction";
import NotFoundPage from "../pages/notFoundPage";
import CookiePolicy from "./layout/cookiePolicy";
import CursorComponent from "./common/cursorComponent";
import ProductivityPage from '../pages/policy/productivityPage';
import KvkkPage from '../pages/policy/kvkkPage';
import NewPage from "../pages/policy/cookiPolicyFooter";
import candidateClarif from "../pages/policy/candidateClarif";
import contactRequest from "../pages/policy/contactRequest";
import suggestions from "../pages/policy/suggestions";
import personalData from "../pages/policy/personalData";
import securityCamera from "../pages/policy/securityCamera";
import websiteGeneral from "../pages/policy/websiteGeneral";
import dataResponsibleForm from "../pages/policy/dataResponsibleForm";
import jobPosting from "../pages/policy/jobPosting";
import internetService from "../pages/policy/internetService";
import intorn from "../pages/policy/intorn";


class RoutesComponent extends Component {
    constructor(props) {
        super(props);
        if(props.location.pathname === SLASH_ROUTE){
            this.props.history.replace(SLASH_ROUTE+LANG_TR)
        }else if(this.props.location.pathname.substring(1,3)===LANG_EN){
            this.props.dispatch(changeLangAction(LANG_EN));
        }

        if(props.location.pathname !== SLASH_ROUTE && props.location.pathname.indexOf(LANG_EN)===-1 && props.location.pathname.indexOf(LANG_TR)===-1 ){
            this.props.history.push("/tr/404");
        }
    }

    isSubPage = (prevProps) => {
        return (this.props.location.pathname.indexOf(CORPORATE_WITHOUT_LINK)>-1 && prevProps.location.pathname.indexOf(CORPORATE_WITHOUT_LINK)>-1) || (this.props.location.pathname.indexOf(CONTACT_WITHOUT_LINK)>-1 && prevProps.location.pathname.indexOf(CONTACT_WITHOUT_LINK)>-1)
    }

    checkLangChanged = (prevProps) => {
        let newUrlArr = this.props.location.pathname.split("/");
        let oldUrlArr = prevProps.location.pathname.split("/");
        if(newUrlArr[1]!==oldUrlArr[1]){
            this.props.dispatch(changeLangAction(newUrlArr[1]==="en"?LANG_EN:LANG_TR));
        }
    }

    componentDidMount() {
        this.script()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname!==this.props.location.pathname && typeof window!=="undefined" && !this.isSubPage(prevProps)){
            window.scrollTo({
                left:0,
                top:0
            })
        }
        if(prevProps.location.pathname!==this.props.location.pathname){
            this.checkLangChanged(prevProps);
        }
        if(this.props.activeLang && (prevProps.activeLang !== this.props.activeLang)) {
            this.changeScript();
        }
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        script.setAttribute("id","cookie-bundle")
        script.setAttribute("type","text/javascript")
        script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=zK60w1658154886073&lang=${this.props.activeLang === "en" ? "en-US": "tr-TR"}`)
        head.appendChild(script);
    }

    changeScript = () => {
        var changeCookieSrc = document.getElementById("cookie-bundle");
        if (this.props.activeLang === "en"){
            changeCookieSrc.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=zK60w1658154886073&lang=en-US`)
        } else {
            changeCookieSrc.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=zK60w1658154886073&lang=tr-TR`)
        }
    }


    render() {
        return (
            <div>
                {/*<CursorComponent/>*/}
                <Route component={HeaderComponent}/>
                <Switch>
                    <Route exact path={HOME} component={HomePage}/>
                    <Route exact path={ARTICLE_DETAIL} component={ArticleDetail}/>
                    <Route exact path={ARTICLE_LIST} component={ArticleList}/>
                    <Route exact path={CORPORATE} component={Corporate}/>
                    <Route exact path={SUSTAINBILITY} component={HomePage}/>
                    <Route exact path={CONTACT} component={Contact}/>
                    <Route exact path={PRIVACY} component={PrivacyPolicy}/>
                    <Route exact path={COOKIE} component={CookiePolicyPage}/>
                    <Route exact path={PRODUCTIVITY} component={ProductivityPage}/>
                    <Route exact path={KVKK} component={KvkkPage}/>
                    <Route  path="/:lang/sub" component={NewPage} />
          <Route  path="/:lang/sub2" component={contactRequest} />
          <Route  path="/:lang/sub3" component={suggestions} />
          <Route  path="/:lang/sub4" component={personalData} />
          <Route  path="/:lang/sub5" component={candidateClarif} />
          <Route  path="/:lang/sub6" component={securityCamera} />
          <Route  path="/:lang/sub7" component={websiteGeneral} />
          <Route  path="/:lang/sub8" component={dataResponsibleForm} />
          <Route  path="/:lang/sub9" component={jobPosting} />
          <Route  path="/:lang/sub10" component={internetService} />
          <Route  path="/:lang/sub11" component={intorn} />
          <Route component={NotFoundPage} />
                    <Route component={NotFoundPage}/>
                </Switch>
                <Route component={FooterComponent}/>
                {/* <CookiePolicy/> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    activeLang: state.langReducer.activeLang,
})
export default withRouter(connect(mapStateToProps)(RoutesComponent));
