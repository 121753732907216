// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const InternetService = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "İnternet Hizmeti Katmanlı Aydınlatma Metni",
            description: `
                Tarafımızca sunulan ücretsiz internet hizmetinden yararlanabilmeniz için siz kıymetli misafirlerimizin birtakım kişisel verilerini işlemekteyiz. ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş olarak; 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi Ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun uyarınca toplu kullanım sağlayıcısı durumundayız. Tesislerimiz içerisinde kaldığınız süre boyunca, siz kıymetli kullanıcılarımızın internet erişimine ilişkin log kayıtlarını; kanuni bir zorunluluk olarak, şirketimizin hukuki ve teknik güvenliğinin temin edilmesi amacıyla, 6698 sayılı Kişisel Verilerin Korunması Kanunu’na uygun olarak, zaman damgalı şekilde muhafaza etmekteyiz.
    
                Bu çerçevede elde edilen log kayıtlarına yalnızca yetkili personelimizin erişimi bulunmaktadır. Söz konusu verileriniz; yetkili kamu kurum ve kuruluşları tarafından talep edilmesi veya şirketimiz içinde gerçekleştirilecek denetim süreçleriyle ilgili hukuki yükümlülüğümüzün yerine getirilmesi amacıyla işlenmekte ve paylaşılmaktadır.
    
                <h2 style="font-size:20px">KİŞİSEL VERİ KATEGORİZASYONU</h2>
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>AÇIKLAMA</th>
                        <th>KATEGORİ</th>
                    </tr>
                    <tr>
                        <td>Kimlik (Adı Soyadı, T.C. Kimlik Numarası, Doğum Tarihi)</td>
                        <td>Kimliği belirli veya belirlenebilir bir gerçek kişiye ait olduğu açık olan ve veri kayıt sistemi içerisinde yer alan; ticari faaliyetlerimizi yürütürken teknik, idari, hukuki ve ticari güvenliğimizi sağlamamız için işlenen kişisel kimlik verilerinizi ifade etmektedir.</td>
                    </tr>
                    <tr>
                        <td>İletişim (Telefon numarası, E-Posta adresi)</td>
                        <td>Ziyaretçilerimizin Wifi internetimize (Misafir hattı) ulaşımı için onay linki ve ya doğrulama kodu gönderebilmek için toplanmakta, işlenmekte ve gerektiğinde ilgil makamlarla paylaşılmaktadır.</td>
                    </tr>
                    <tr>
                        <td>İşlem Güvenliği Bilgisi (IP Adresi, MAC Adresi, Ziyaret Edilen Site ve Log Kayıtları)</td>
                        <td>Ticari faaliyetlerimizi yürütürken Misafir internet hattımıza giren ziyaretçilerin 6698 kanunu gereği teknik, idari, hukuki ve ticari güvenliğimizi sağlamamız ve 5651 kanunun gereği 2 yıl süreyle, kullanılan cihaza ait işlem güvenliği bilgileri toplanmakta, işlenmekte ve gerektiğinde ilgili makamlarla paylaşılmaktadır.</td>
                    </tr>
                </table>
                
                Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere Kişisel Verilerinin Korunmasına İlişkin Aydınlatma Metnimizden ulaşabilirsiniz.
            `,
        },
        en: {
            title: "Layered Disclosure Text for Internet Services",
            description: `
                In order to benefit from the free internet service provided by us, we process certain personal data of you, our valued guests. As ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş, we are a public access provider under Law No. 5651 on the Regulation of Publications Made on the Internet and Combating Crimes Committed by These Publications. During your stay in our facilities, we keep the log records related to your internet access; as a legal obligation, to ensure our company's legal and technical security, in accordance with the Law on the Protection of Personal Data No. 6698, with timestamping.
    
                Access to the log records obtained in this context is only available to our authorized personnel. Your data is processed and shared for the purpose of fulfilling our legal obligations related to audits that will be conducted by authorized public institutions and organizations.
    
                <h2 style="font-size:20px">PERSONAL DATA CATEGORIZATION</h2>
                <table border="1" style="width: 100%; border-collapse: collapse;">
                    <tr>
                        <th>DESCRIPTION</th>
                        <th>CATEGORY</th>
                    </tr>
                    <tr>
                        <td>Identity (Full Name, T.C. Identity Number, Date of Birth)</td>
                        <td>This refers to personal identity data processed to ensure our technical, administrative, legal, and commercial security while conducting our business activities, which clearly belongs to an identifiable person and is included in the data recording system.</td>
                    </tr>
                    <tr>
                        <td>Contact (Phone Number, Email Address)</td>
                        <td>It is collected, processed, and shared with relevant authorities when necessary to send confirmation links or verification codes to our visitors for accessing our Wifi internet (Guest Line).</td>
                    </tr>
                    <tr>
                        <td>Transaction Security Information (IP Address, MAC Address, Visited Site, and Log Records)</td>
                        <td>To ensure our technical, administrative, legal, and commercial security as required by the Law No. 6698, transaction security information related to the device used is collected, processed, and shared with relevant authorities when necessary for a period of 2 years under the requirements of Law No. 5651.</td>
                    </tr>
                </table>
                
                You can find detailed information about the processing of your personal data in our Personal Data Protection Disclosure Text.
            `,
        },
    };
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];
    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };
    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(InternetService);