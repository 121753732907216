// src/pages/CookiePolicyPage.js
import React, { useEffect } from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const DataResposibleForm = ({ activeLang }) => {

    useEffect(() => {
        const downloadBtn = document.getElementById('download-btn');
        if (downloadBtn) {
            downloadBtn.addEventListener('click', handleDownload);
        }

        // Cleanup function
        return () => {
            if (downloadBtn) {
                downloadBtn.removeEventListener('click', handleDownload);
            }
        };
    }, []);
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "VERİ SORUMLUSUNA BAŞVURU FORMU",
            description: `
                <h1 style="font-size:26px">VERİ SORUMLUSUNA BAŞVURU FORMU</h1>
                <h2 style="font-size:20px">A. Genel Açıklamalar</h2>
                <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 11. maddesi kapsamında herkes, veri sorumlusu sıfatıyla ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“Ünlü Tekstil ya da Şirket”) aşağıda belirtilen hususlarda başvuruda bulunma hakkına sahiptir:</p>
                <ul>
                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>6698 sayılı KVKK’nın 7. maddesi uyarınca, bu Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel veriler resen veya ilgili kişinin talebi üzerine öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                    <li>(e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                </ul>
    
           <h2 style="font-size:20px">B. Başvuruya İlişkin Kişisel Verilerin İşlenmesi</h2>
            <p><a href="/tr/sub8">Veri Sorumlusuna Başvuru Formu</a> ile başvurucunun kimliğinin tespiti ve başvurunun yönetilmesi amacıyla sınırlı ve bağlantılı olarak kişisel veri (Ad, Soyad, T.C. Kimlik Numarası, Telefon Numarası, E-Posta, Adres, İmza) işlenmektedir. Söz konusu kişisel veriler, gerektiği takdirde başvuru talebinin niteliğine göre yalnızca ilgili birimler/kişiler ve kanunlarca yetkili kurum/kuruluşlar ile paylaşılabilmektedir. Başvuru ile ilgili detaylı bilgilendirme için Kişisel Veri Sahibi Başvuru ve Yapılacak İşlemler Prosedürü’ne bakabilirsiniz.</p>
    
            <h2 style="font-size:20px">C. Başvuru Yöntemi, Başvurunun Yapılacağı Adres ve Başvuru Gönderiminde Belirtilecek Bilgiyi Gösterir Tablo</h2>
            <table border="1">
                <tr>
                    <th>Başvuru Yöntemi</th>
                    <th>Başvurunun Yapılacağı Adres</th>
                    <th>Başvuru Gönderiminde Belirtilecek Bilgi</th>
                </tr>
                <tr>
                    <td>Elden başvuru (Başvuru sahibinin bizzat gelerek kimliğini tevsik edici belge ile başvurması gerekmektedir. Vekaleten başvuru yapılması durumunda noter tasdikli vekaletnamenin yanınızda bulundurulması gerekmektedir)</td>
                    <td>ÜNLÜ TEKSTİL SAN. VE TİC.A.Ş.<br>Emek Mah. Sıvat Cad. No:15 Sancaktepe / İstanbul</td>
                    <td>Zarfın üzerine “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</td>
                </tr>
                <tr>
                    <td>Noter vasıtasıyla tebligat</td>
                    <td>ÜNLÜ TEKSTİL SAN. VE TİC.A.Ş.<br>Emek Mah. Sıvat Cad. No:15 Sancaktepe / İstanbul</td>
                    <td>Tebligat zarfına “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</td>
                </tr>
                <tr>
                    <td>Email yolu ile başvuru yapılması (Yanda belirtilen email adreslerine gereği ve bilgi için bu formu imzalı ve pdf/jpeg formatında taranmış olarak gönderiniz. Islak imzalı aslını lütfen posta yoluyla merkez adresimize gönderiniz.)</td>
                    <td>unlutekstil@hs01.kep.tr<br>Gereği için kvkk@unlu.com</td>
                    <td>Emailde konu kısmına “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi” yazılacaktır.</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">D. Başvuru Sahibi İletişim Bilgileri</h2>
            <table border="1">
                <tr><td>Ad:</td><td>___________________</td></tr>
                <tr><td>Soyad:</td><td>___________________</td></tr>
                <tr><td>TC Kimlik Numarası:</td><td>___________________ *(Son 6 hane)</td></tr>
                <tr><td>Telefon Numarası:</td><td>___________________</td></tr>
                <tr><td>E-posta:</td><td>___________________</td></tr>
                <tr><td>Adres:</td><td>___________________</td></tr>
            </table>
    
            <h2 style="font-size:20px">E. Lütfen Şirketimiz ile Olan İlişkinizi Belirtiniz</h2>
            <table border="1">
                <tr>
                    <td>☐ Müşteri</td>
                </tr>
                <tr>
                    <td>☐ Ziyaretçi</td>
                </tr>
                <tr>
                    <td>☐ Eski Çalışanım</td>
                    <td>Çalıştığım Yıllar: ______________</td>
                </tr>
                <tr>
                    <td>☐ Diğer</td>
                </tr>
                <tr>
                    <td>☐ Çalışan</td>
                </tr>
                <tr>
                    <td>☐ İş Başvurusu / Özgeçmiş Paylaşımı Yaptım</td>
                    <td>Tarih: ______________</td>
                </tr>
                <tr>
                    <td>☐ Üçüncü Kişi Firma Çalışanıyım</td>
                    <td>Lütfen çalıştığınız firma ve pozisyon bilgisini belirtiniz: ______________</td>
                </tr>
                <tr>
                    <td>Şirketimiz içerisinde iletişimde olduğunuz Kişi/Birim: ______________</td>
                </tr>
                <tr>
                    <td>Konu: ______________</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">F. Lütfen KVKK Kapsamındaki Talebinizi Belirtiniz</h2>
            <table border="1">
                <tr>
                    <th>Talep No</th>
                    <th>Talep Konusu</th>
                    <th>Kanuni Dayanak</th>
                    <th>Seçiminiz</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Şirketinizin hakkımda kişisel veri işleyip işlemediğini öğrenmek istiyorum</td>
                    <td>KVKK m. 11/1-a</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Eğer şirketiniz hakkımda kişisel veri işliyor ise bu veri işleme faaliyetlerine ilişkin bilgi talep ediyorum</td>
                    <td>KVKK m. 11/1-b</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Eğer Şirketiniz hakkımda kişisel veri işliyorsa bunların işlenme amacını ve işlenme amacına uygun kullanılıp kullanmadığını öğrenmek istiyorum</td>
                    <td>KVKK m. 11/1-c</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Eğer kişisel verilerim yurtiçinde veya yurtdışında üçüncü kişilere aktarılıyorsa, bu üçüncü kişileri bilmek istiyorum</td>
                    <td>KVKK m. 11/1-ç</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Kişisel verilerimin eksik veya yanlış işlendiği düşünüyorum ve bunların düzeltilmesini istiyorum</td>
                    <td>KVKK m. 11/1-d</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Kişisel verilerimin kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığını düşünüyorum ve bu çerçevede kişisel verilerimin;</td>
                    <td>KVKK m. 11/1-e</td>
                    <td>
                        a) Silinmesini ☐<br>
                        b) Yok edilmesini ☐<br>
                        c) Anonim hale getirilmesini ☐
                    </td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>Eksik ve yanlış işlendiğini düşündüğüm kişisel verilerimin (5 Numaralı Talep) aktarıldığı üçüncü kişiler nezdinde de düzeltilmesini istiyorum.</td>
                    <td>KVKK m. 11/1-f</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>Kişisel verilerimin kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığını düşünüyorum (6 Numaralı Talep) ve bu çerçevede kişisel verilerimin aktarıldığı üçüncü kişiler nezdinde de;</td>
                    <td>KVKK m. 11/1-f</td>
                    <td>
                        a) Silinmesini ☐<br>
                        b) Yok Edilmesini ☐<br>
                        c) Anonim Hale Getirilmesini ☐
                    </td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>Şirketiniz tarafından işlenen kişisel verilerimin münhasıran otomatik sistemler vasıtasıyla analiz edildiğini ve bu analiz neticesinde şahsım aleyhine bir sonuç doğduğunu düşünüyorum. Bu sonuca itiraz ediyorum.</td>
                    <td>KVKK m. 11/1-g</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>Kişisel verilerimin kanuna aykırı işlenmesi nedeniyle zarara uğradım. Bu zararın tazmini talep ediyorum.</td>
                    <td>KVKK m. 11/1-ğ</td>
                    <td>☐</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">G. Lütfen Talebiniz İle İlgili Bir Açıklama Yapınız</h2>
            <p>___________________________________________________________</p>
    
            <h2 style="font-size:20px">H. Başvurunuza Vereceğimiz Yanıtın Tarafınıza Ne Şekilde Bildirilmesini İstiyorsunuz?</h2>
            <table border="1">
                <tr>
                    <td>☐ Adresime gönderilmesini istiyorum.</td>
                </tr>
                <tr>
                    <td>☐ E-Posta adresime gönderilmesini istiyorum.<br>(E-Posta yöntemini seçmeniz halinde size daha hızlı yanıt verebileceğiz.)</td>
                </tr>
                <tr>
                    <td>☐ Elden teslim almak istiyorum.<br>(Vekaleten teslim alınmasının istenmesi durumunda noter tasdikli vekaletname veya yetki belgesi olması gerekmektedir.)</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">İ. Başvuru Sahibinin Beyanı</h2>
            <p>Yukarıda belirttiğim talepler doğrultusunda, Şirketinize yapmış olduğum başvurumun Kanun’un 13. Maddesi uyarınca değerlendirilerek tarafıma bilgi verilmesini istemekteyim.</p>
            <p>Bu başvuruda tarafınıza sağlamış olduğum belge ve bilgilerin doğru, güncel ve şahsıma ait olduğunu beyan ve taahhüt ederim.</p>
            <table border="1">
                <tr>
                    <td>Başvuruda bulunan kişinin; Adı, Soyadı:</td>
                    <td>___________________</td>
                </tr>
                <tr>
                    <td>İmzası:</td>
                    <td>___________________</td>
                </tr>
                <tr>
                    <td>Başvuru Tarihi:</td>
                    <td>___________________</td>
                </tr>
            </table>
            `,
        },
        en: {
            title: "APPLICATION FORM TO THE DATA CONTROLLER",
            description: `
                <h1 style="font-size:26px">APPLICATION FORM TO THE DATA CONTROLLER</h1>
                <h2 style="font-size:20px">A. General Explanations</h2>
                <p>Under Article 11 of the Law No. 6698 on the Protection of Personal Data ("KVKK"), everyone has the right to apply to ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş ("Ünlü Tekstil or Company") as a data controller regarding the matters specified below:</p>
                <ul>
                    <li>Learn whether personal data is processed,</li>
                    <li>If personal data has been processed, request information regarding it,</li>
                    <li>Learn the purpose of processing personal data and whether they are used in accordance with their purpose,</li>
                    <li>Know the third parties to whom personal data is transferred, both domestically and internationally,</li>
                    <li>Request the correction of personal data if processed incorrectly or incompletely,</li>
                    <li>Request the deletion or destruction of personal data if the reasons requiring processing have disappeared, in accordance with the conditions stipulated by law and upon the request of the relevant person,</li>
                    <li>Request notification of the operations carried out under subparagraphs (e) and (f) to the third parties to whom personal data is transferred,</li>
                    <li>Object to the occurrence of a result against the person by analyzing the processed data exclusively through automated systems,</li>
                    <li>Request compensation for damages in case of damage due to unlawful processing of personal data.</li>
                </ul>
    
                <h2 style="font-size:20px">B. Processing of Personal Data Related to the Application</h2>
                <p>The application form for the data controller processes personal data (Name, Surname, T.C. Identity Number, Phone Number, Email, Address, Signature) limited and connected for the purpose of identifying the applicant and managing the application. These personal data may only be shared with relevant units/individuals and authorized institutions/organizations by law, depending on the nature of the application request. For detailed information regarding the application, please refer to the Personal Data Owner Application and Procedures to be Followed.</p>
    
                <h2 style="font-size:20px">C. Application Method, Address for Application, and Information to be Specified in Application Submission</h2>
                 <h2 style="font-size:20px">B. Processing of Personal Data Related to the Application</h2>
            <p>Personal data (Name, Surname, T.C. Identity Number, Phone Number, Email, Address, Signature) is processed in a limited and relevant manner for the purpose of identifying the applicant and managing the application through the Application Form to the Data Controller. These personal data may be shared only with relevant units/individuals and authorized institutions/organizations by law, depending on the nature of the application request. For detailed information regarding the application, please refer to the Personal Data Owner Application and Procedures to be Followed.</p>
    
            <h2 style="font-size:20px">C. Application Method, Address for Application, and Information to be Provided in Application Submission</h2>
            <table border="1">
                <tr>
                    <th>Application Method</th>
                    <th>Address for Application</th>
                    <th>Information to be Provided in Application Submission</th>
                </tr>
                <tr>
                    <td>Application in person (The applicant must come in person with an identity verification document. If applying by proxy, a notarized power of attorney must be present.)</td>
                    <td>ÜNLÜ TEKSTİL SAN. VE TİC.A.Ş.<br>Emek Mah. Sıvat Cad. No:15 Sancaktepe / İstanbul</td>
                    <td>The envelope must state “Information Request within the Scope of the Personal Data Protection Law”.</td>
                </tr>
                <tr>
                    <td>Notification via Notary</td>
                    <td>ÜNLÜ TEKSTİL SAN. VE TİC.A.Ş.<br>Emek Mah. Sıvat Cad. No:15 Sancaktepe / İstanbul</td>
                    <td>The notification envelope must state “Information Request within the Scope of the Personal Data Protection Law”.</td>
                </tr>
                <tr>
                    <td>Application via Email (Please send this form signed and scanned in pdf/jpeg format to the specified email addresses for necessary action and information. Please send the wet-signed original to our central address by post.)</td>
                    <td>unlutekstil@hs01.kep.tr<br>For action: kvkk@unlu.com</td>
                    <td>The subject line of the email must state “Information Request within the Scope of the Personal Data Protection Law”.</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">D. Contact Information of the Applicant</h2>
            <table border="1">
                <tr><td>Name:</td><td>___________________</td></tr>
                <tr><td>Surname:</td><td>___________________</td></tr>
                <tr><td>T.C. Identity Number:</td><td>___________________ *(Last 6 digits)</td></tr>
                <tr><td>Phone Number:</td><td>___________________</td></tr>
                <tr><td>Email:</td><td>___________________</td></tr>
                <tr><td>Address:</td><td>___________________</td></tr>
            </table>
    
            <h2 style="font-size:20px">E. Please Indicate Your Relationship with Our Company</h2>
            <table border="1">
                <tr>
                    <td>☐ Customer</td>
                </tr>
                <tr>
                    <td>☐ Visitor</td>
                </tr>
                <tr>
                    <td>☐ Former Employee</td>
                    <td>Years Worked: ______________</td>
                </tr>
                <tr>
                    <td>☐ Other</td>
                </tr>
                <tr>
                    <td>☐ Employee</td>
                </tr>
                <tr>
                    <td>☐ I Made a Job Application / Shared My CV</td>
                    <td>Date: ______________</td>
                </tr>
                <tr>
                    <td>☐ I Am an Employee of a Third Party Company</td>
                    <td>Please indicate the company and position you worked for: ______________</td>
                </tr>
                <tr>
                    <td>Contact Person/Unit within our Company: ______________</td>
                </tr>
                <tr>
                    <td>Subject: ______________</td>
                </tr>
            </table>
    
            <h2 style="font-size:20px">F. Please Indicate Your Request within the Scope of KVKK</h2>
            <table border="1">
                <tr>
                    <th>Request No</th>
                    <th>Request Subject</th>
                    <th>Legal Basis</th>
                    <th>Your Choice</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>I want to know whether your company processes personal data about me</td>
                    <td>KVKK m. 11/1-a</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>If your company processes personal data about me, I request information regarding these processing activities</td>
                    <td>KVKK m. 11/1-b</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>If your company processes personal data about me, I want to know the purpose of processing and whether it is used accordingly</td>
                    <td>KVKK m. 11/1-c</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>If my personal data is transferred to third parties domestically or abroad, I want to know these third parties</td>
                    <td>KVKK m. 11/1-ç</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>I think my personal data has been processed incorrectly or incompletely and I want it corrected</td>
                    <td>KVKK m. 11/1-d</td>
                    <td>☐</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Although my personal data has been processed in accordance with the law and other relevant legal provisions, I believe the reasons necessitating its processing have ceased to exist and I request the following for my personal data:</td>
                    <td>KVKK m. 11/1-e</td>
                    <td>
                        a) To be deleted ☐<br>
                        b) To be destroyed      ☐<br>
                    c) To be anonymized ☐
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>I want the correction of my personal data (Request No. 5) to be made also with respect to third parties to whom it has been transferred.</td>
                <td>KVKK m. 11/1-f</td>
                <td>☐</td>
            </tr>
            <tr>
                <td>8</td>
                <td>Although my personal data has been processed in accordance with the law and other relevant legal provisions, I believe that the reasons necessitating its processing have ceased to exist (Request No. 6) and I request the following to be done with respect to third parties to whom my personal data has been transferred:</td>
                <td>KVKK m. 11/1-f</td>
                <td>
                    a) To be deleted ☐<br>
                    b) To be destroyed ☐<br>
                    c) To be anonymized ☐
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>I believe that my personal data processed by your company has been analyzed exclusively through automated systems and that this analysis has resulted in an adverse outcome for me. I object to this outcome.</td>
                <td>KVKK m. 11/1-g</td>
                <td>☐</td>
            </tr>
            <tr>
                <td>10</td>
                <td>I have suffered damage due to the unlawful processing of my personal data. I request compensation for this damage.</td>
                <td>KVKK m. 11/1-ğ</td>
                <td>☐</td>
            </tr>
        </table>

        <h2 style="font-size:20px">G. Please Provide an Explanation Regarding Your Request</h2>
        <p>___________________________________________________________</p>

        <h2 style="font-size:20px">H. How Would You Like Us to Notify You of Our Response to Your Application?</h2>
        <table border="1">
            <tr>
                <td>☐ I would like it to be sent to my address.</td>
            </tr>
            <tr>
                <td>☐ I would like it to be sent to my email address.<br>(Choosing the email method will allow us to respond to you faster.)</td>
            </tr>
            <tr>
                <td>☐ I would like to receive it in person.<br>(If you want it to be received by proxy, a notarized power of attorney or authorization document is required.)</td>
            </tr>
        </table>

        <h2 style="font-size:20px">I. Declaration of the Applicant</h2>
        <p>In line with the requests I have specified above, I request that my application to your company be evaluated in accordance with Article 13 of the Law and that I be informed.</p>
        <p>I declare and undertake that the documents and information I have provided in this application are accurate, up-to-date, and belong to me.</p>
        <table border="1">
            <tr>
                <td>Name, Surname of the Applicant:</td>
                <td>___________________</td>
            </tr>
            <tr>
                <td>Signature:</td>
                <td>___________________</td>
            </tr>
            <tr>
                <td>Application Date:</td>
                <td>___________________</td>
            </tr>
        </table>
            `,
        },
    };
    const pageContent = content[activeLang];

    const handleDownload = () => {
        const blob = new Blob([pageContent.description], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'veri_sorumlusuna_basvuru_formu.html';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    
        downloadButton: {
            padding: '12px 25px',
            backgroundColor: '#007BFF', // Mavi arka plan
            color: 'white', // Beyaz metin
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s, transform 0.2s',
            marginTop: '20px', // Üstten boşluk
            display: 'inline-block',
        },
    };
    
    // Hover ve aktif durum için stil
    const hoverStyle = {
        ...styles.downloadButton,
        backgroundColor: '#0056b3', // Koyu mavi hover
        transform: 'scale(1.05)', // Hoverda büyütme
    };
    
    return (
        <div>
    {
        pageContent ?
        <div style={styles.cookiePolicyBlock}>
            <h3 style={styles.title}>{pageContent.title}</h3>

            <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />

            {/* Download Button */}
            <button
    onClick={handleDownload}
    style={styles.downloadButton}
    onMouseOver={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
    onMouseOut={(e) => e.currentTarget.style.backgroundColor = styles.downloadButton.backgroundColor}
>
    {activeLang === 'tr' ? 'Formu İndir' : 'Download Form'}
</button>
        </div>
        :
        <NotFoundPage {...this.props} />
    }
</div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(DataResposibleForm);