// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const PersonalData = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "Kişisel Veri Sahibi Başvuru ve Yapılacak İşlemler Prosedürü",
            description: `
                <h1 style="font-size:26px">Kişisel Veri Sahiplerinin Taleplerinin Karşılanması</h1>
                <h2 style="font-size:20px">1.1 Kişisel Veri Sahiplerinin Hakları</h2>
                <p>Kişisel veri sahipleri, KVK Kanunu uyarınca aşağıdaki haklara sahiptirler:</p>
                <ul>
                    <li>a. Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
                    <li>b. Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>c. Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>d. Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>e. Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>f. İşlenmesini gerektiren sebeplerin ortadan kalktığı kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>g. İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>h. Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararının giderilmesini talep etme.</li>
                </ul>
                
                <h2 style="font-size:20px">1.2 Hakların İleri Sürülemeyeceği Durumlar</h2>
                <p>KVK Kanunu amacına ve temel ilkelerine uygun ve orantılı olmak kaydıyla aşağıda sayılan durumlarda kişisel veri sahipleri, zararın giderilmesini talep etme hakkı hariç diğer haklarını ileri süremezler:</p>
                <ul>
                    <li>a. Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması,</li>
                    <li>b. İlgili kişinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi,</li>
                    <li>c. Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması,</li>
                    <li>d. Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.</li>
                </ul>
    
                <h2 style="font-size:20px">1.3 Hakların Kullanması Usulü</h2>
                <p>Kişisel veri sahipleri; yazılı olarak, kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da Şirketimize daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adreslerini kullanarak haklarını ileri sürebilirler.</p>
                <p>Kişisel veri sahipleri tarafından Şirketimize yapılacak başvurular, tercihen KVK Kanunu Başvuru Formu aracılığıyla kabul edilir. Buna karşın bir kişisel veri sahibinin bu formu doldurmaksızın Şirketimize geçerli bir başvuruda bulunması mümkündür. Böyle bir durumda öncelikle kişisel veri sahibinden KVK Kanunu Başvuru Formu’nu doldurması rica edilir, eğer bu rica reddedilir veya 5 işgünü içerisinde yanıtlanmazsa kişisel veri sahibinin yaptığı ilk başvuru işleme alınır.</p>
                <p>Her halükârda kişisel veri sahibin tarafından yapılan başvuruda aşağıdaki hususların yer alıp almadığı kontrol edilerek zorunlu alanlarda eksiklik olması durumunda bunlar tamamlanır veya tamamlattırılır:</p>
                <ul>
                    <li>Ad, soyad ve başvuru yazılı ise imza,</li>
                    <li>Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası,</li>
                    <li>Tebligata esas yerleşim yeri veya iş yeri adresi,</li>
                    <li>Varsa bildirime esas elektronik posta adresi, telefon ve faks numarası,</li>
                    <li>Talep konusu.</li>
                </ul>
    
                <h2 style="font-size:20px">1.4 Haklara İstinaden Yapılan Başvuruların Cevaplandırılması</h2>
                <p>Kişisel veri sahipleri tarafından yapılan başvurular en kısa sürede ve en geç ilk başvuru tarihinden itibaren 30 gün içinde aşağıdaki prosedüre uygun olarak cevaplanır:</p>
                <ol>
                    <li>Başvuru: Başvuru, KVK Görevlisine iletilir ve uygun görülmesi halinde kişisel veri sahibine başvurusunun alındığına dair bir mesaj gönderilir.</li>
                    <li>Kimlik ve İçerik Doğrulama: KVK Görevlisi, başvurunun asgari başvuru şartlarını içerip içermediğini ve kişisel veri sahibinin kimliğinin doğru olup olmadığını değerlendirir, gerekli görmesi halinde kişisel veri sahibinden ek bilgi ve belge talebinde bulunur.</li>
                    <li>Sınıflandırma: Kişisel veri sahibinin ait olduğu kişisel veri sahibi kategorisine uygun olarak KVK Görevlisi, başvurunun cevaplanması için kendisini veya ilgili sorumlu yöneticiyi görevlendirir.</li>
                    <li>Cevap Hazırlığı: Görevlendirilen sorumlu yönetici, Kişisel Veri Sahibi Cevap Formu çerçevesinde talebi kabul eden veya gerekçesiyle reddeden bir cevap hazırlar. İhtiyaç duyulması halinde, Şirketimizin kişisel veri sahibi taslak cevaplarından, kişisel veri işleme envanterinden ve KVK Politika ve Prosedürleri çerçevesinde tutulan kayıtlardan faydalanılır. Şirketimizin tüm çalışanları bu kapsamda görevlendirilen sorumlu yöneticiye gerekli desteği sağlar. Hazırlanan cevap KVK Görevlisi’ne iletilir.</li>
                    <li>Cevap Yazısı: Cevap yazısı aşağıdaki konuları içerir:
                        <ul>
                            <li>a. Veri sorumlusunun (Şirketimiz) veya varsa temsilcisine ait bilgiler,</li>
                            <li>b. Başvuru sahibinin; adı ve soyadı, Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar için uyruğu, pasaport numarası veya varsa kimlik numarası, tebligata esas yerleşim yeri veya iş yeri adresi, varsa bildirime esas elektronik posta adresi, telefon ve faks numarası,</li>
                            <li>c. Talep konusu,</li>
                            <li>d. Şirketimizin başvuruya ilişkin açıklamaları.</li>
                        </ul>
                    </li>
                    <li>Onaylama: Cevap metni, KVK Görevlisi tarafından incelenir ve uygun gördüğü değişiklikler varsa bunlarla birlikte yine KVK Görevlisi tarafından onaylanır. KVK Görevlisi, gerekli görmesi halinde başvuruyu ve cevap metnini KVK Komitesi’nde gündeme taşıyabilir, bu durumda cevap metnine ilişkin nihai onayı KVK Komitesi verir.</li>
                    <li>Cevaplama: Onaylanan cevap, kişisel veri sahibinin talebinin kabulü yönündeyse talebin gereği yerine getirilir ve cevap metni, başvuruda belirtilen iletişim tercihine uygun olarak KVK Görevlisi tarafından kişisel veri sahibine gönderilir.</li>
                    <li>Arşivleme: Başvuru, cevap ve sürece ilişkin diğer kayıtlar KVK Görevlisi tarafından arşivlenir.</li>
                </ol>
    
                <h2 style="font-size:20px">1.5 Başvuruya İlişkin Maliyet</h2>
                <p>Kişisel veri sahibinin talebi, kendisine verilecek cevabın basılı ortamda olması yönündeyse Şirketimiz tarafından verilecek cevabın on sayfaya kadar olan kısmı için bir ücret alınmaz ancak on sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem ücreti alınır. Başvuruya cevabın CD, taşınabilir bellek gibi bir kayıt ortamında verilmesi halinde ilgili kayıt ortamının maliyeti, kişisel veri sahibinden talep edilir. Başvurunun, Şirketimizin bir hatasından kaynaklanması hâlinde alınan tüm ücret ve masraflar kişisel veri sahibine iade edilir. Kişisel veri sahibinin talebi, kendisine verilecek cevabın elektronik ortamda sağlanması yönündeyse kişisel veri sahibinden herhangi bir ücret talep edilmez.</p>
    
                <h2 style="font-size:20px">1.6 Kişisel Veri Sahibinin KVK Kurulu’na Şikâyeti</h2>
                <p>Kişisel veri sahibinin Şirketimize yaptığı başvuruyu takiben KVK Kurulu’na şikâyette bulunması durumunda; KVK Görevlisi, KVK Kurulu ile gerekli iletişimi sağlar, gerekmesi halinde Şirketimizin şikâyete cevabını hazırlar, cevaba ilişkin KVK Komitesi’nin görüş ve onayını alır, nihai olarak cevabı KVK Kurulu’na bildirir.</p>
    
                <h1 style="font-size:26px">2. Veri Güvenliği İhlali Müdahale Planı</h1>
                <p>Şirketimizce işlenen kişisel verilerin kanuni olmayan yollarla üçüncü kişiler tarafından ele geçirilmesi hâlinde, bu durum en kısa sürede ilgili kişisel veri sahiplerine ve KVK Kurulu’na bildirilir. KVK Kurulu’nun 24.01.2019 tarih ve 2019/10 sayılı kararı uyarınca bu kapsamda, kişisel veri güvenliği bakımından kayda değer vakıalar Şirketimiz genelinde izlenir ve bunların veri güvenliği ihlali teşkil edip etmediği değerlendirilerek gereği yapılır.</p>
    
                <h2 style="font-size:20px">2.1 Vakıaların KVK Görevlisi’ne Bildirilmesi</h2>
                <p>Şirketimiz çalışanları, veri güvenliği bakımından herhangi bir şekilde haberdar oldukları kayda değer her türlü vakıayı, derhal KVK Görevlisi’ne bildirir. Bildirim için KVK Görevlisi’nin kurumsal e-posta adresi kullanılır.</p>
                 <h2 style="font-size:20px">2.2 İhlallerin Değerlendirilmesi</h2>
            <p>KVK Görevlisi, kendisine ulaştırılan bütün vakıa bildirimlerinin KVK Komitesi tarafından kendisi başkanlığında aşağıdaki prosedür uyarınca soruşturulmasını ve elde edilen bulgular uyarınca gereğinin yapılmasını sağlar. Aşağıda açıklanan prosedürün soruşturma, değerlendirme ve uygulama aşamalarının, vakıa bildiriminin Şirketimiz çalışanlarına ulaşmasını takiben en geç 72 saat içerisinde tamamlanması esastır.</p>
            <ol>
                <li><strong>Soruşturma:</strong> KVK Görevlisi, vakıa hakkında bir ön inceleme yaparak eksik bilgileri tamamlar ve gerekli görmesi halinde Şirketimizin insan kaynakları departmanlarına ilaveten, veri koruma alanındaki hukuk ve bilişim danışmanlarını bilgilendirerek sürece dâhil eder.</li>
                <li><strong>Değerlendirme:</strong> KVK Görevlisi, KVK Komitesi’ni toplayarak vakıayı gündeme taşır. KVK Komitesi, etkilenen kişisel veri sınıflarının niteliği ve niceliği uyarınca bir kritiklik derecesi belirler ve vakıanın bir veri güvenliği ihlali teşkil edip etmediğini, gerekli görmesi halinde Şirketimizin veri koruma alanındaki hukuk ve bilişim danışmanlarının da desteğiyle değerlendirir.</li>
                <li><strong>Uygulama:</strong> Vakıa hakkında gerçekleştirilen değerlendirmeler neticesinde KVK Komitesi, vakıanın Şirketimiz ve kişisel veri sahipleri üzerinde doğurabileceği riskleri asgariye indirgemek üzere gerekli idari ve teknik önlemleri tespit eder ve uygular ya da uygulattırır. Takiben gerekli hukuki değerlendirmeleri yaparak KVK Kurulu’na, etkilenen kişisel veri sahiplerine ve/veya güvenlik güçlerine bildirim yapılmasının gerekli olup olmadığına karar verir. KVK Komitesi’nin bu yöndeki kararı KVK Görevlisi tarafından yönetime sunulur ve yönetimin nihai kararı doğrultusunda gereği yapılır.</li>
            </ol>
            <p>Bu doğrultuda:</p>
            <ul>
                <li>a. KVK Kurulu’na yapılacak bildirimler, kurulun internet sitesinde yer alan “Kişisel Veri İhlali Bildirim Formu” aracılığıyla gerçekleştirilir.</li>
                <li>b. İhlalden etkilenen kişisel veri sahiplerine yapılacak bildirimler, ilgili kişinin iletişim bilgisi Şirketimizde mevcutsa doğrudan, mevcut değilse Şirketimiz internet sitesinde duyuru yayımlanması gibi diğer uygun yöntemlerle yapılır. Bildirimde açık ve sade bir dil kullanılır. Bildirim metninde asgari olarak aşağıdaki hususlara yer verilir:
                    <ul>
                        <li>İhlalin ne zaman gerçekleştiği,</li>
                        <li>Kişisel veri kategorileri bazında (kişisel veri / özel nitelikli kişisel veri ayrımı yapılarak) hangi kişisel verilerin ihlalden etkilendiği,</li>
                        <li>İhlalin kişisel veri sahibi üzerindeki olası sonuçları,</li>
                        <li>İhlalin olumsuz etkilerinin azaltılması için alınan veya alınması önerilen tedbirler,</li>
                        <li>Kişisel veri sahibinin, ihlale ilişkin bilgi almasını sağlayacak irtibat kişilerinin isim ve iletişim detayları veya Şirketimizin internet sitesinin tam adresi ile çağrı merkezi ve benzeri iletişim yolları.</li>
                    </ul>
                </li>
                <li>4. Raporlama: Vakıaya ilişkin tüm bilgiler ve vakıa kapsamında gerçekleştirilen tüm işlemler, KVK Komitesi tarafından hazırlanacak bir rapor ile kayıt altına alınır. Bu raporda vakıa sonucu çıkarılan dersler ve bunlara uygun iyileştirme noktalarından yola çıkılarak KVK Komitesinin önerileri listelenir. Rapor yönetime sunulur ve önerilere ilişkin gerekli iyileştirmelerin yapılması KVK Görevlisi tarafından takip edilir.</li>
                <li>5. Arşivleme: Vakıaya ilişkin elde edilen tüm bilgiler, bildirimler, işletilen prosedürler ve hazırlanan raporlar, KVK Görevlisi tarafından kayıt altına alınır ve arşivlenir.</li>
            </ul>

            <h2 style="font-size:20px">ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</h2>
        `
    },
        en: {
            title: "Personal Data Owner Application and Procedures",
            description: `
                <h1 style="font-size:26px">1. Fulfillment of Personal Data Owners' Requests</h1>
                <h2 style="font-size:20px">1.1 Rights of Personal Data Owners</h2>
                <p>Personal data owners have the following rights under the KVK Law:</p>
                <ul>
                    <li>a. To learn whether their personal data is being processed,</li>
                    <li>b. If their personal data has been processed, to request information regarding it,</li>
                    <li>c. To learn the purpose of processing their personal data and whether it is used in accordance with that purpose,</li>
                    <li>d. To know the third parties to whom their personal data has been transferred, domestically or abroad,</li>
                    <li>e. To request the correction of their personal data if it is incomplete or incorrectly processed and to request notification of these corrections to the third parties to whom the personal data has been transferred,</li>
                    <li>f. To request the deletion or destruction of their personal data if the reasons for processing are no longer valid and to request notification of these actions to the third parties to whom the personal data has been transferred,</li>
                    <li>g. To object to the emergence of a result against themselves by analyzing their processed data solely through automated systems,</li>
                    <li>h. To request compensation for damages resulting from the unlawful processing of their personal data.</li>
                </ul>
    
                <h2 style="font-size:20px">1.2 Situations Where Rights Cannot Be Asserted</h2>
                <p>In accordance with the purposes and fundamental principles of the KVK Law, personal data owners cannot assert their rights, except for the right to request compensation for damages, in the following situations:</p>
                <ul>
                    <li>a. If the processing of personal data is necessary for the prevention of a crime or for a criminal investigation,</li>
                    <li>b. If the personal data has been publicized by the relevant person themselves,</li>
                    <li>c. If the processing of personal data is necessary for the execution of duties of public institutions and organizations or professional organizations with public institution status, based on the authority granted by law,</li>
                    <li>d. If the processing of personal data is necessary for the protection of the economic and financial interests of the state regarding budget, tax, and financial matters.</li>
                </ul>
    
                <h2 style="font-size:20px">1.3 Procedure for Exercising Rights</h2>
                <p>Personal data owners can assert their rights in writing using registered electronic mail (KEP) address, secure electronic signature, mobile signature, or the electronic mail addresses previously communicated to our Company and registered in our system.</p>
                <p>Applications made to our Company by personal data owners are preferably accepted through the KVK Law Application Form. However, it is possible for a personal data owner to make a valid application to our Company without filling out this form. In such a case, the personal data owner will be asked to fill out the KVK Law Application Form, and if this request is rejected or not responded to within 5 business days, the initial application of the personal data owner will be processed.</p>
                <p>In any case, the application made by the personal data owner will be checked for the presence of the following items. If there are deficiencies in the mandatory fields, these will be completed:</p>
                <ul>
                    <li>Name, surname, and signature if the application is in writing,</li>
                    <li>For Turkish citizens, T.C. identification number; for foreigners, nationality, passport number, or identification number if available,</li>
                    <li>Address for notifications,</li>
                    <li>If available, email address for notifications, phone and fax numbers,</li>
                    <li>Subject of the request.</li>
                </ul>
    
                <h2 style="font-size:20px">1.4 Response to Applications Based on Rights</h2>
                <p>Applications made by personal data owners are responded to as soon as possible, and at the latest within 30 days from the date of the initial application, according to the following procedure:</p>
                <ol>
                    <li>Application: The application is forwarded to the KVK Officer, and if deemed appropriate, a message is sent to the personal data owner confirming the receipt of their application.</li>
                    <li>Identity and Content Verification: The KVK Officer evaluates whether the application contains the minimum application requirements and whether the identity of the personal data owner is correct. If necessary, additional information and documents may be requested from the personal data owner.</li>
                    <li>Classification: According to the category of the personal data owner, the KVK Officer assigns themselves or the relevant responsible manager to respond to the application.</li>
                    <li>Response Preparation: The designated responsible manager prepares a response accepting or rejecting the request within the framework of the Personal Data Owner Response Form. If necessary, the company's draft responses, personal data processing inventory, and records kept within the framework of KVK Policies and Procedures are utilized. All employees of our company provide necessary support to the designated responsible manager. The prepared response is forwarded to the KVK Officer.</li>
                    <li>Response Letter: The response letter includes the following topics:
                        <ul>
                            <li>a. Information about the data controller (our Company) or its representative, if any,</li>
                            <li>b. Information about the applicant; name and surname, T.C. identification number for Turkish citizens; nationality, passport number, or identification number for foreigners; address for notifications; if available, email address for notifications, phone, and fax numbers,</li>
                            <li>c. Subject of the request,</li>
                            <li>d. Explanations regarding the application from our Company.</li>
                        </ul>
                    </li>
                    <li>Approval: The response text is reviewed by the KVK Officer, and any changes deemed appropriate are approved by the KVK Officer. If necessary, the application and response text may be brought to the agenda of the KVK Committee, in which case the final approval of the response text is given by the KVK Committee.</li>
                    <li>Response: If the approved response involves the acceptance of the personal data owner's request, the necessary actions are taken, and the response text is sent to the personal data owner according to the communication preference specified in the application.</li>
                    <li>Archiving: The application, response, and other records related to the process are archived by the KVK Officer.</li>
                </ol>
    
                <h2 style="font-size:20px">1.5 Cost Related to the Application</h2>
            <p>If the personal data owner's request is for a printed response, no fee will be charged for the first ten pages of the response provided by our Company, but a processing fee of 1 Turkish Lira will be charged for each page exceeding ten pages. If the response to the application is provided on a storage medium such as a CD or a USB drive, the cost of the relevant storage medium will be requested from the personal data owner. If the application is due to a fault of our Company, all fees and expenses incurred will be refunded to the personal data owner. If the personal data owner's request is for an electronic response, no fee will be charged.</p>
            
            <h2 style="font-size:20px">1.6 Complaint of the Personal Data Owner to the KVK Board</h2>
            <p>If the personal data owner files a complaint with the KVK Board following their application to our Company, the KVK Officer will ensure necessary communication with the KVK Board, prepare our Company's response to the complaint if necessary, obtain the opinions and approval of the KVK Committee regarding the response, and finally inform the KVK Board of the response.</p>
            
            <h2 style="font-size:20px">2 DATA SECURITY BREACH INTERVENTION PLAN</h2>
            <p>In the event that personal data processed by our Company is unlawfully accessed by third parties, this situation will be reported to the relevant personal data owners and the KVK Board as soon as possible. According to the KVK Board's decision dated 24.01.2019 and numbered 2019/10, significant incidents regarding personal data security will be monitored across our Company, and it will be evaluated whether these constitute a data security breach, and necessary actions will be taken.</p>
            
            <h2 style="font-size:20px">2.1 Reporting Incidents to the KVK Officer</h2>
            <p>Employees of our Company must immediately report any significant incident they become aware of regarding data security to the KVK Officer. The corporate email address of the KVK Officer will be used for notifications.</p>
            
            <h2 style="font-size:20px">2.2 Evaluation of Breaches</h2>
            <p>The KVK Officer ensures that all incident notifications submitted to them are investigated by the KVK Committee under their chairmanship according to the procedures outlined below, and that necessary actions are taken based on the findings obtained. It is essential that the investigation, evaluation, and implementation phases of the procedures described below be completed within a maximum of 72 hours after the incident notification reaches our Company employees.</p>
            <ol>
                <li><strong>Investigation:</strong> The KVK Officer conducts a preliminary review of the incident, completes any missing information, and if necessary, informs our Company's human resources departments and includes legal and IT consultants in the field of data protection.</li>
                <li><strong>Evaluation:</strong> The KVK Officer convenes the KVK Committee and brings the incident to the agenda. The KVK Committee determines a criticality level based on the nature and quantity of the affected personal data categories and evaluates whether the incident constitutes a data security breach, with the support of our Company's legal and IT consultants if deemed necessary.</li>
                <li><strong>Implementation:</strong> As a result of the evaluations conducted regarding the incident, the KVK Committee identifies and implements or has implemented the necessary administrative and technical measures to minimize the risks that the incident may pose to our Company and the personal data owners. Subsequently, necessary legal evaluations are made to determine whether notification to the KVK Board, affected personal data owners, and/or security forces is required. The KVK Officer presents the KVK Committee's decision in this regard to the management, and actions are taken in accordance with the management's final decision.</li>
            </ol>
            <p>In this regard:</p>
            <ul>
                <li>a. Notifications to the KVK Board are made through the “Personal Data Breach Notification Form” available on the Board's website.</li>
                <li>b. Notifications to affected personal data owners are made directly if the individual's contact information is available in our Company, or through other appropriate methods such as publishing an announcement on our Company's website if not. Clear and plain language is used in the notification. The notification text at a minimum includes the following:
                    <ul>
                        <li>When the breach occurred,</li>
                        <li>Which personal data has been affected by the breach, categorized by personal data / special category personal data,</li>
                        <li>The potential consequences of the breach for the personal data owner,</li>
                        <li>Measures taken or recommended to mitigate the negative effects of the breach,</li>
                        <li>The names and contact details of the individuals who can provide information regarding the breach, or the full address of our Company's website along with contact center and similar communication methods.</li>
                    </ul>
                </li>
                <li>4. Reporting: All information related to the incident and all actions carried out within the scope of the incident will be recorded in a report prepared by the KVK Committee. This report lists the lessons learned from the incident and the recommendations of the KVK Committee based on suitable improvement points. The report is submitted to the management, and the KVK Officer follows up on the necessary improvements related to the recommendations.</li>
                <li>5. Archiving: All obtained information regarding the incident, notifications, procedures operated, and reports prepared will be recorded and archived by the KVK Officer.</li>
            </ul>

            <h2 style="font-size:20px">ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş</h2>
        `
    }
};
    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };
    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(PersonalData);