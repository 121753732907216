import async from "async";
import * as WebService from "../services/webService";
import * as actionTypes from "../constants/actionTypes";

const homeData = (data) => ({
  type: actionTypes.GET_HOME_DATA,
  payload: data,
});

const homeArticles = (data) => ({
  type: actionTypes.GET_HOME_ARTICLES,
  payload: data,
});

const corporateData = (data) => ({
  type: actionTypes.GET_CORPORATE_DATA,
  payload: data,
});

export const getHomeDataInit = (dispatch, params) => {
  return new Promise((resolve, failure) => {
    async.series(
      [
        (cb) => {
          WebService.getHomeData()
            .then((res) => {
              if (res.data.success) {
                cb(null, res.data.success);
              }
            })
            .catch((err) => {
              console.log("ERR--", err);
            });
        },
        (cb) => {
          WebService.homeArticles(params)
            .then((res) => {
              if (res.data.success) {
                cb(null, res.data.success);
              }
            })
            .catch((err) => {
              console.log("ERR--", err);
            });
        },
      ],
      (err, result) => {
        dispatch(homeArticles(result[1]));
        dispatch(homeData(result[0]));
        resolve(result);
      }
    );
  });
};
export const getCorporateDataInit = (dispatch) => {
  return new Promise((resolve, failure) => {
    async.series(
      [
        (cb) => {
          WebService.getCorporateData()
            .then((res) => {
              if (res.data.success) {
                cb(null, res.data.success);
              }
            })
            .catch((err) => {});
        },
      ],
      (err, result) => {
        dispatch(corporateData(result[0]));
        resolve(result);
      }
    );
  });
};
