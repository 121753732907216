import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  ARTICLE_LIST_WITHOUT_PARAM,
  SLASH_ROUTE,
} from "../../../constants/routePaths";
import { connect } from "react-redux";

class MenuListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubMenu: null, // Track the active submenu
    };
  }

  // Function to toggle submenu
  toggleSubMenu = (index) => {
    this.setState({
      activeSubMenu: this.state.activeSubMenu === index ? null : index,
    });
  };

  // Function to close the submenu
  closeSubMenu = () => {
    this.setState({ activeSubMenu: null });
  };

  // Dil seçeneklerine göre alt menü öğelerini döndüren fonksiyon
  getSubMenuItems = () => {
    const { activeLang } = this.props;

    const subMenuItems = {
      tr: [
        { name: "Çerez Aydınlatma Metni", link: "sub" },
        { name: "İletişim Talep Aydınlatma Metni", link: "sub2" },
        { name: "Öneri ve Şikayet Aydınlatma Metni", link: "sub3" },
        { name: "Kişisel Veri Sahibi Başvuru ve Yapılacak İşlemler Prosedürü", link: "sub4" },
        { name: "Çalışan Adayı Aydınlatma Beyanı", link: "sub5" },
        { name: "Güvenlik Kameralarına ilişkin Aydınlatma Beyanı", link: "sub6" },
        { name: "İnternet Sitesi Genel Aydınlatma Metni", link: "sub7" },
        { name: "Veri Sorumlusuna Başvuru Formu", link: "sub8" },
        { name: "İş ilanı - İş Başvuru Sorumsuzluk Kaydı", link: "sub9" },
        { name: "İnternet Hizmeti Katmanlı Aydınlatma Metni", link: "sub10" },
        { name: "Stajyer Aydınlatma Beyanı", link: "sub11" },
      ],
      en: [
        { name: "Cookie Clarification Text", link: "sub" },
        { name: "Contact Request Clarification Text", link: "sub2" },
        { name: "Suggestion and Complaint Clarification Text", link: "sub3" },
        { name: "Personal Data Owner Application and Procedures", link: "sub4" },
        { name: "Candidate Clarification Statement", link: "sub5" },
        { name: "Security Camera Clarification Statement", link: "sub6" },
        { name: "Website General Clarification Text", link: "sub7" },
        { name: "Application Form for Data Responsible", link: "sub8" },
        { name: "Job Posting - Job Application Liability Record", link: "sub9" },
        { name: "Internet Service Layered Clarification Text", link: "sub10" },
        { name: "Intern Clarification Statement", link: "sub11" },
      ],
    };

    return subMenuItems[activeLang] || subMenuItems['tr'];
  };

  // Dil seçeneğine göre başlığı döndüren fonksiyon
  getHeaderTitle = () => {
    const { activeLang } = this.props;
    const titles = {
      tr: "Kişisel Veri Koruma",
      en: "Personal Data Protection",
    };

    return titles[activeLang] || titles['tr'];
  };

  render() {
    let { tags, activeLang, footerData } = this.props;
    const subMenuItems = this.getSubMenuItems();
    const headerTitle = this.getHeaderTitle();

    // Ekran genişliğine göre konum ayarları
    const isMobile = window.innerWidth <= 600; // Mobil belirleme
    const positionStyle = isMobile
    ? { right: 0, top: 0 } :
       { left: 200, bottom: 0 }

    return (
      <div className="right-block" style={{ display: "flex" }}>
        <ul className="footer-list-item">
          {footerData && (
            <>
              <li>
                <NavLink
                  to={`${SLASH_ROUTE}${activeLang}/${footerData.company_link}`}
                >
                  {footerData.company}
                </NavLink>
              </li>
              {footerData.companyMenus.map((menuItem, key) => (
                <li key={key}>
                  <NavLink
                    to={`${SLASH_ROUTE}${activeLang}/${footerData.company_link}/${menuItem.link}`}
                  >
                    {menuItem.name}
                  </NavLink>
                </li>
              ))}
              <li
                onClick={() => this.toggleSubMenu("subMenu")}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "14px",
                  position: "relative",
                  fontFamily: "Montserrat-Regular",
                }}
              >
                {headerTitle}
                {this.state.activeSubMenu === "subMenu" && (
                  <div style={{ position: "absolute", zIndex: 1000, ...positionStyle }}>
                    <ul
                      style={{
                        listStyle: "none",
                        padding: "5px", // Satır arası boşlukları azalt
                        border: "1px solid #ccc",
                        background: "white",
                        width: 250, // Genişliği artır
                        margin: 0,
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      }}
                    >
                      <button
                        onClick={this.closeSubMenu}
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        X
                      </button>
                      {subMenuItems.map((subItem, subIndex) => (
                        <li key={subIndex} style={{ padding: "-3px 0", color: "black", cursor: "pointer", textAlign: "left" }}>
                          <NavLink
                            to={`${SLASH_ROUTE}${activeLang}/${subItem.link}`}
                            style={{
                              display: "block",
                              textDecoration: "none",
                              color: "black",
                              fontSize: "12px", // Yazı boyutunu düşür
                            }}
                            activeStyle={{
                              color: "orange", // Aktif bağlantı rengi
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.color = "orange"}
                            onMouseLeave={(e) => e.currentTarget.style.color = "black"}
                          >
                            {subItem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </>
          )}
        </ul>
        <ul className="footer-list-item is-menu-list">
                    {
                        tags && tags.slice(0,Math.ceil(tags.length/2)).map((item,key)=>
                            <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`}>{item.tag_name}</NavLink></li>
                        )
                    }
                </ul>
                <ul className="footer-list-item is-menu-list">
                    {
                        tags && tags.slice(Math.ceil(tags.length/2)).map((item,key)=>
                            <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}${ARTICLE_LIST_WITHOUT_PARAM}/${item.tag_link}`}>{item.tag_name}</NavLink></li>
                        )
                    }
                </ul>
                {
                    footerData &&
                    <ul className="footer-list-item">
                        <li><NavLink to={`${SLASH_ROUTE}${activeLang}/${footerData.contact_link}`}>{footerData.contact}</NavLink></li>
                        {
                            footerData.contactMenus.map((menuItem,key)=>
                                <li key={key}><NavLink to={`${SLASH_ROUTE}${activeLang}/${footerData.contact_link}${menuItem.link?"/"+menuItem.link:``}`}>{menuItem.name}</NavLink></li>
                            )
                        }
                    </ul>
                }

        {/* Diğer menü içeriği burada yer alacak */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.articleListTagsReducer.articleListTags,
  activeLang: state.langReducer.activeLang,
  footerData: state.layoutReducer[state.langReducer.activeLang]
    ? state.layoutReducer[state.langReducer.activeLang].footer
    : null,
});

export default connect(mapStateToProps)(MenuListComponent);