// src/pages/CookiePolicyPage.js
import React from 'react';
import NotFoundPage from '../notFoundPage';
import { connect } from "react-redux";

const ContactRequest = ({ activeLang }) => {
    // Static content for the cookie policy
    const content = {
        tr: {
            title: "İletişim Talep Formu Aydınlatma Metni",
            description: `
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“Ünlü Tekstil ya da Şirket”) yürütmekte olduğu faaliyetler nedeniyle kişisel verilerinizin işlenmesinde 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)’na uygun olarak her türlü teknik ve hukuki tedbiri almaktadır. İlgili kişiler, kişisel verilerinin işlenmesi, üçüncü kişilere aktarılması, kişisel veri toplamanın hukuki sebepleri ile KVKK’da yer alan hakları konularında detaylı bilgiye aşağıdaki aydınlatma metninden ulaşabilirler.</p>
                <p>İşbu Aydınlatma Metni, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 10. Maddesi kapsamında, https://unlutekstil.com.tr/ internet sitesinin İletişim Talep Formu bölümüne istinaden bizimle paylaşabileceğiniz kişisel verileriniz ile ilgili olarak, veri sorumlusu sıfatıyla ÜNLÜ TEKSTİL tarafından, sizi aydınlatmak ve bilgilendirmek amacıyla hazırlanmıştır.</p>
                <p>İşbu Aydınlatma Metni ile ÜNLÜ TEKSTİL’ın kimliği (KVKK 10.a), bizimle paylaşabileceğiniz kişisel verilerinizin hangi amaçlarla işleneceği (KVKK 10.b), işlenen kişisel verilerinizin kimlere hangi amaçlarla aktarılabileceği (KVKK 10.c), veri toplama yöntemlerimiz ile hukuki sebepleri (KVKK 10.ç) ve sahip olduğunuz haklarınız (KVKK 10.d) konusunda sizleri bilgilendirmeyi amaçlamaktayız.</p>
                <h2 style="font-size:20px">1. Veri Sorumlusunun Kimliği (KVKK 10.a)</h2>
                <p>İşbu internet sitesi, ÜNLÜ TEKSTİL’e ait olup, tarafımızla paylaşabileceğiniz kişisel verileriniz, veri sorumlusu sıfatı ile ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ nezdinde muhafaza edilecek ve korunacaktır.</p>
                <h2 style="font-size:20px">2. Kişisel Verilerinizin İşlenme Amacı (KVKK 10.b)</h2>
                <p>İnternet sitesi kullanıcılarının bizimle iletişime geçmesine imkân tanımak ve bunu da mümkün olan en kolay şekilde sağlayabilmek amacıyla https://unlutekstil.com.tr/ alan adlı internet sitesinin İletişim Talep Formu bölümünde iletişim taleplerinizi bizimle paylaşabileceğiniz bir alan oluşturduk.</p>
                <p>İşbu alanda size hitap edebilmemiz ve talebinize konu hususu değerlendirmek için; adınızı ve soyadınızı, sizinle irtibat kurabilmek ve/veya talebiniz hakkında size bilgi vermek ya da iletinize cevap verebilmek için telefon numarası ve e-posta adresinizi talep etmekte ve bizimle paylaştığınız kişisel verilerinizi iletinizde belirttiğiniz amaç doğrultusunda işlemekteyiz. Yine size daha iyi yardımcı olabilmek, iletinize en iyi cevabı verebilmek amacıyla iletinize konu hususu araştırabilir ve bu araştırmalarda elde ettiğimiz kişisel verilerinizi işleyebiliriz.</p>
                <h2 style="font-size:20px">3. Kişisel Verilerinizin Aktarılması ve Aktarılma Amacı (KVKK 10.c)</h2>
                <p>Bu bölümde bizimle paylaşabileceğiniz kişisel veriler, talebiniz hakkında işlem yapabilmek, olası sorularınıza cevap verebilmek veya talebinize konu işlemi gerçekleştirebilmemiz için, gerekli olduğu ölçüde, şirketimiz bünyesinde görevli ve iletinize konu ilgili bölümden sorumlu çalışma arkadaşlarımızla, bizimle gizlilik sözleşmesi çerçevesinde çalışan bizden bağımsız ancak bize hizmet veren avukatlarımız, danışmanlarımız ve sair çözüm ortaklarımızla, iletiniz kapsamında işlem yapabilmek amacıyla gerekli olması halinde ilgili 3. Kişilerle ve resmi kurum ve kuruluşlarla paylaşılabilecektir.</p>
                <h2 style="font-size:20px">4. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi (KVKK 10.ç)</h2>
                <p>Burada bizimle paylaştığınız kişisel verileriniz, Kanun’un 5. Maddesinde kaleme alınan “ilgili kişi tarafından alenileştirilmiş olması” hukuki sebebi ile ve ayrıca yine talebiniz mevcut ve/veya olası bir sözleşme ilişkisi ile ilgili ise “bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması” hukuki sebebi ile ve yine talebiniz kapsamında yükümlülüklerimizin ifası ile ilgili ise “veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi” hukuki sebebine ve son olarak da talebinize konu olabilecek “bir hakkın tesisi, kullanılması veya korunması” hukuki sebebi ile işlenmektedir.</p>
                <p>Kişisel verileriniz, https://unlutekstil.com.tr/ alan adlı internet sitesinin İletişim Talep Formu bölümünden paylaşmanız suretiyle tamamen veya kısmen otomatik yolla işlenmektedir.</p>
                <h2 style="font-size:20px">5. Kanuni Haklarınız (KVKK 10.d)</h2>
                <p>Kanun’un 11. Maddesinde haklarınız kaleme alınmış olup bu madde kapsamında aşağıdaki haklara sahip olduğunuzu belirtmek isteriz:</p>
                <ul>
                    <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme;</li>
                    <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme;</li>
                    <li>Kişisel verilerinizin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme;</li>
                    <li>Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme;</li>
                    <li>Kişisel verilerinizin eksik veya yanlış işlenmiş ise düzeltilmesini isteme;</li>
                    <li>Kanun’un 7. Maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme;</li>
                    <li>Kişisel verilerinizin aktarıldığı üçüncü kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme;</li>
                    <li>Kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme; ve</li>
                    <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranılması halinde zararın giderilmesini talep etme.</li>
                </ul>
                <p>Burada yer alan haklarınız konusunda taleplerinizi Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e göre ÜNLÜ TEKSTİL’e KVKK <a href="/tr/sub8">Veri Sorumlusuna Başvuru Formu</a>’nu doldurarak bildirebilirsiniz.</p>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ</p>
            `
        },
        en: {
            title: "Contact Request Form Clarification Text",
            description: `
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET A.Ş (“ÜNLÜ TEKSTİL or the Company”) takes all kinds of technical and legal measures in accordance with the Law on the Protection of Personal Data No. 6698 (“KVKK”) in the processing of your personal data due to its activities. Relevant persons can access detailed information regarding the processing of their personal data, the transfer to third parties, the legal grounds for collecting personal data, and the rights listed in the KVKK from the clarification text below.</p>
                <p>This Clarification Text has been prepared by ÜNLÜ TEKSTİL as the data controller to inform you regarding your personal data that you may share with us in relation to the Communication Request Form section of the website https://unlutekstil.com.tr/ in accordance with Article 10 of the Law on the Protection of Personal Data No. 6698.</p>
                <p>With this Clarification Text, we aim to inform you about the identity of ÜNLÜ TEKSTİL (KVKK 10.a), the purposes for which your personal data may be processed (KVKK 10.b), to whom your processed personal data may be transferred and for what purposes (KVKK 10.c), our data collection methods and legal grounds (KVKK 10.ç), and your rights (KVKK 10.d).</p>
                <h2 style="font-size:20px">1. Identity of the Data Controller (KVKK 10.a)</h2>
                <p>This website belongs to ÜNLÜ TEKSTİL, and your personal data shared with us will be stored and protected by ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ as the data controller.</p>
                <h2 style="font-size:20px">2. Purpose of Processing Your Personal Data (KVKK 10.b)</h2>
                <p>To enable internet users to contact us and to provide this as easily as possible, we have created a section in the Communication Request Form of the website https://unlutekstil.com.tr/ where you can share your communication requests with us.</p>
                <p>In this section, in order to address you and evaluate the subject of your request, we request your name and surname, phone number, and email address to contact you and/or provide information about your request or respond to your message, and we process your personal data shared with us in accordance with the purpose stated in your message. To assist you better and provide the best response to your inquiry, we may investigate the subject of your message and process the personal data we obtain during these investigations.</p>
                <h2 style="font-size:20px">3. Transfer of Your Personal Data and Purpose of Transfer (KVKK 10.c)</h2>
                <p>The personal data you share with us may be shared with our colleagues responsible for the relevant department within our company to process your request, answer any questions you may have, or carry out the transaction related to your request, in necessary cases with independent lawyers, consultants, and other solution partners working with us under confidentiality agreements, and if necessary, with relevant third parties and official authorities.</p>
                <h2 style="font-size:20px">4. Method of Collection and Legal Basis of Personal Data (KVKK 10.ç)</h2>
                <p>The personal data you share with us is processed by the legal ground of "being publicized by the relevant person" stated in Article 5 of the Law, and also if your request is related to an existing and/or potential contractual relationship, it is processed by the legal ground of "directly related to the establishment or performance of a contract," and if it is related to the fulfillment of our obligations, it is processed by "the data controller's legal obligation" and finally by the legal ground of "the establishment, use or protection of a right" that may be the subject of your request.</p>
                <p>Your personal data is processed fully or partially automatically by sharing it through the Communication Request Form section of the website https://unlutekstil.com.tr/.</p>
                <h2 style="font-size:20px">5. Your Legal Rights (KVKK 10.d)</h2>
                <p>Article 11 of the Law outlines your rights, and we would like to state that you have the following rights under this article:</p>
                <ul>
                    <li>To learn whether your personal data is processed;</li>
                    <li>If your personal data is processed, to request information regarding it;</li>
                    <li>To learn the purpose of processing your personal data and whether it is used in accordance with that purpose;</li>
                    <li>To know the third parties to whom your personal data is transferred, domestically or abroad;</li>
                    <li>To request the correction of your personal data if it is incomplete or incorrectly processed;</li>
                    <li>To request the deletion or destruction of your personal data under the conditions stipulated in Article 7 of the Law;</li>
                    <li>To request that the operations carried out under (d) and (e) be notified to third parties to whom your personal data is transferred;</li>
                    <li>To object to the emergence of a result against you by analyzing your processed data exclusively through automated systems;</li>
                    <li>To request compensation for damages in case of damage due to the unlawful processing of your personal data.</li>
                </ul>
                <p>You can submit your requests regarding your rights mentioned here to ÜNLÜ TEKSTİL by filling out the Application Form for the Data Controller according to the Communiqué on the Procedures and Principles of Application to the Data Controller.</p>
                <p>ÜNLÜ TEKSTİL SANAYİ VE TİCARET ANONİM ŞİRKETİ</p>
            `
        }
    };
    
    // Aktif dile göre içerik seçimi
 
    // Aktif dile göre içerik seçimi
  

    const pageContent = content[activeLang];

    const styles = {
        cookiePolicyBlock: {
            maxWidth: '90%',
            margin: '100px auto',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            fontFamily: 'Montserrat-Regular',
        },
        title: {
            fontSize: '26px', // Updated to 26px
            fontWeight: 400,  // Set font weight to 400
            color: '#333',
            marginBottom: '15px',
            textAlign: 'center',
            fontFamily: 'Montserrat-Regular',

        },
       
        subtitle: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#444',
            marginTop: '20px',
            marginBottom: '10px',
            fontFamily: 'Montserrat-Regular',

        },
        list: {
            listStyleType: 'disc',
            paddingLeft: '20px',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        listItem: {
            marginBottom: '10px',
            lineHeight: '1.6',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
        textContent: {
            fontSize: '20px', // Set to 20px
            fontWeight: 400,  // Set font weight to 400
            color: '#555',
            lineHeight: '1.6',
            fontFamily: 'Montserrat-Regular',

        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            fontSize: '20px', // Set to 20px
            overflowX: 'auto',
            fontFamily: 'Montserrat-Regular',

        },
        th: {
            backgroundColor: '#eee',
            padding: '10px',
            textAlign: 'left',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',
            
        },
        td: {
            padding: '10px',
            border: '1px solid #ddd',
            fontWeight: 400,  // Set font weight to 400
            fontFamily: 'Montserrat-Regular',

        },
    };

    return (
        <div>
            {
                pageContent ?
                <div style={styles.cookiePolicyBlock} >
                    <h3 style={styles.title}>{pageContent.title}</h3>

                    <div className='text-item' style={styles.textContent} dangerouslySetInnerHTML={{ __html: pageContent.description }} />
                </div>
                    
                :
                <NotFoundPage {...this.props} />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(ContactRequest);